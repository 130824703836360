@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #18181B;
    color: #EEE; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrap-text { /* If you added a class */
  word-break: break-word;
}

.navbar { 
  display: flex;
  justify-content: space-between;  /* Distribute items on opposite ends */
  align-items: center;          /* Vertically center content */
}


/* index.css or styles.css */
.pagination button {
  /* Your button styles */
}

.pagination {
  display: flex; /* Arrange buttons horizontally */
  gap: 10px; /* Add spacing between buttons */
}

.pagination button {
  padding: 8px 16px; 
  border: 1px solid lightgray;
  border-radius: 4px; 
  background-color: white;
  cursor: pointer; 
}

/* Styling for longer input box */
.input-modified { 
    width: 225px; /* Or adjust the width as needed */
    border: 0px solid; 
    background-color: #18181B;  /* A darker background color */
    color: #EEE;    
    margin-right: 15px;
    padding-left: 5px;
    padding: 12px 15px;
}


/* Assuming your spinner container has a class like 'spinner-container' */
.spinner-container {
  margin-top: 20px; /* Adjust the value as needed */
}

.search-noresults {
  display: flex;
  justify-content: center; 
  align-items: center;     
}

/* Styling for the form (you might already have this) */
.search-form-container { 
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for search results container */
.search-results-container {
  width: 50%;  /* Adjust the width as needed */
  margin: 20px auto; /* Centers the results with some top margin */
} 

